import clone from "clone";
import { newInvoice } from "./config";
import actions from "./actions";

const initState = {
  invoices: [],
  initialInvoices: false,
  currentInvoice: {},
  editableInvoice: {},
  isNewInvoice: false,
  enableEditView: false,
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.UPDATE_INVOICE: {
      return {
        ...state,
        editableInvoice: action.invoices,
        initialInvoices: true,
        isNewInvoice: true,
        enableEditView: true,
      };
    }

    case actions.TOGGLE_VIEW:
      return {
        ...state,
        enableEditView: action.view,
      };
    case actions.IS_NEW:
      return {
        ...state,
        isNewInvoice: action.view,
      };
    case actions.UPDATE_EDIT_INVOICE:
      return {
        ...state,
        editableInvoice: clone(action.invoice),
      };
    case actions.RESET_INVOICE:
      return {
        ...state,
        editableInvoice: { ...newInvoice },
      };
    default:
      return state;
  }
}
