const actions = {
  DATA_UPDATE: "DATA_UPDATE",
  CLEAR_DATA: "CLEAR_DATA",
  update: (data) => ({
    type: actions.DATA_UPDATE,
    payload: data,
  }),
  logout: () => ({
    type: actions.CLEAR_DATA,
  }),
};
export default actions;
