import React, { useEffect, useState } from "react";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { ThemeProvider } from "styled-components";
import themes from "@iso/config/theme/theme.config";
import AppLocale from "@iso/config/translation";
import Loader from "@iso/components/utility/loader";
import axios from "axios";
import actions from "./redux/user/actions";
export default function AppProvider({ children }) {
  const { update } = actions;
  const dispach = useDispatch();
  const [loading, setLoading] = useState(false);
  const { locale } = useSelector((state) => state.LanguageSwitcher.language);
  const { themeName } = useSelector(
    (state) => state.ThemeSwitcher.changeThemes
  );
  const currentAppLocale = AppLocale[locale];
  const isLoggedIn = useSelector((state) => state.Auth.idToken);
  useEffect(() => {
    if (isLoggedIn) {
      setLoading(true);
      axios.defaults.headers.common["Authorization"] = `Bearer ${isLoggedIn}`;

      try {
        axios
          .get("https://user.adanto.hub.jellydev.pl/api/users/my/data")
          .then(({ data }) => {
            dispach(update(data));
            setLoading(false);
          });
      } catch (e) {
        console.log(e);
      }
    }
  }, [isLoggedIn]);

  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <ThemeProvider theme={themes[themeName]}>
          {loading ? <Loader /> : children}
        </ThemeProvider>
      </IntlProvider>
    </ConfigProvider>
  );
}
