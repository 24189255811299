import { all, takeEvery, put, fork } from "redux-saga/effects";

import actions from "./actions";

export function* dataUpdate() {
  yield takeEvery("DATA_UPDATE", function* ({ payload }) {
    // yield put({
    //   type: actions.DATA_UPDATE,
    //   data: data,
    // });
  });
}

export default function* rootSaga() {
  yield all([fork(dataUpdate)]);
}
